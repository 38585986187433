.ant-table {
  &-thead {
    position: sticky;
    top: 0;
    left: 0;
    // z-index: 1;

    > tr {
      > th {
        color: var(--placeholder-gray);
        background: var(--neutral-2);
        font-size: var(--_table-th-size);
        line-height: var(--_th-line-height);
        font-weight: 700;
        padding: 8px calc(var(--_cell-padding) * 1px);
        border-bottom: none;

        &:first-child {
          padding-left: var(
            --_first-th-left,
            calc(var(--_cell-padding, 8) * 1px)
          );
        }

        &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          display: none;
        }

        .ant-table-column-sorter {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          top: 1px;

          &-up,
          &-down {
            color: var(--input-placeholder-color);

            &.active {
              color: var(--primary-dark-blue-2);
            }
          }

          &s {
            &:hover {
              color: inherit;
            }

            &[ng-reflect-sort-order='ascend'] {
              .ant-table-column-sorter {
                &-down {
                  display: none;
                }
              }
            }

            &[ng-reflect-sort-order='descend'] {
              .ant-table-column-sorter {
                &-up {
                  display: none;
                }

                &-down {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &-tbody {
    > tr {
      cursor: pointer;
      background: var(--_table-bg);

      &.has-hover:hover {
        td {
          transition: ease-in-out 0.3s;

          div {
            background: #f5f5f5;
            transition: ease-in-out 0.3s;
          }

          div.missing-vital,
          div.missing-vital-abnormal {
            background: #fff;
          }
        }
      }

      &.has-even {
        div {
          background: #f3f4f8;
        }
      }

      &.stripped {
        // &:nth-child(even):not(.even),
        &.even {
          aiomed-skeleton {
            background: var(--table-bg-even);
            display: flex;
            align-items: center;

            .skeleton {
              background: linear-gradient(
                90deg,
                #ffffff 25%,
                #f4f5f5 50%,
                #e0e1e2 75%
              );
              background-size: 400% 100%;
            }
          }

          div,
          .checkbox::before {
            background: var(--table-bg-even);
          }

          div.missing-vital-abnormal,
          div.missing-vital {
            background: #fff;
          }
        }
      }

      &[severity='critical'] {
        div {
          background: var(--primary-red-1);
        }

        .checkbox {
          &::before {
            background: var(--primary-red-1);
          }
        }
      }

      &[severity='major'] {
        div {
          background: var(--calendula-gold-1);
        }

        .checkbox {
          &::before {
            background: var(--calendula-gold-1);
          }
        }
      }

      &[severity='info'] {
        div {
          background: var(--primary-green-1);
        }

        .checkbox {
          &::before {
            background: var(--primary-green-1);
          }
        }
      }

      > td {
        color: var(--primary-dark-2);
        font-size: var(--_table-font-size);
        line-height: 22px;
        font-weight: 400;
        border-bottom: 1px solid var(--card-border-color);
        padding: calc(var(--_row-padding) * 1px) 0;
        background-color: var(--_table-bg);

        &:first-child {
          div {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }

        &:last-child {
          div {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }

        aiomed-skeleton {
          height: calc((var(--_row-height) - var(--_row-padding) * 2) * 1px);
        }

        > div,
        aiomed-skeleton,
        aiomed-report-list-cell > div,
        aiomed-report-list-pressure-cell > div,
        aiomed-report-review-abnormal-cell > div,
        aiomed-report-review-abnormal-pressure-cell > div,
        &.checkbox {
          display: flex;
          align-items: center;
          padding: 0 calc(var(--_cell-padding) * 1px);
          transition: background var(--transition-time);
          height: 100%;

          &:not(.ant-table-expanded-row-fixed) {
            height: calc((var(--_row-height) - var(--_row-padding) * 2) * 1px);
          }

          &.ant-table-expanded-row-fixed {
            justify-content: center;
          }
        }

        &.checkbox {
          display: table-cell;
          position: relative;
          background: var(--_table-bg);
          padding: 0;

          &::before {
            content: '';
            background: #fff;
            position: absolute;
            width: 100%;
            height: 42px;
            height: calc((var(--_row-height) - var(--_row-padding) * 2) * 1px);
            left: 0;
            top: calc(var(--_row-padding) * 1px);
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            transition: var(--transition-time);
          }
        }
      }

      &:not(.ant-table-placeholder) {
        &:hover {
          td {
            background: transparent;

            &.checkbox {
              &::before {
                background: #f5f5f5;
                transition: var(--transition-time);
              }
            }
          }
        }
      }
    }
  }

  &-filter-trigger {
    &:hover {
      background-color: transparent;
    }
  }

  &-wrapper {
    --_row-height: 54;
    --_row-padding: 6;
    --_table-bg: #fff;
    --_table-font-size: 14px;
    --_table-th-size: 14px;
    --_cell-padding: 8;

    @media (max-width: 1024px) and (orientation: portrait),
      (max-width: 1280px) and (orientation: landscape) {
      --_table-font-size: 23px; //28
      --_table-th-size: 26px;
      --_row-height: 114;
      --_row-padding: 21;
      --_cell-padding: 6;
      --_th-line-height: 2.5rem;
      --_first-th-left: 28px;
    }

    &.tablet-forced {
      --_table-font-size: 28px;
      --_table-th-size: 26px;
      --_th-line-height: 2.5rem;
      --_row-height: 114;
      --_row-padding: 21;
      --_cell-padding: 6;
      --_first-th-left: 28px;
    }

    &.desktop-forced {
      --_row-height: 54;
      --_row-padding: 6;
      --_table-font-size: 14px;
      --_table-th-size: 14px;
      --_cell-padding: 8;
      --_first-th-left: 19px;
      --_th-line-height: 1rem;
    }
  }
}

.ant-pagination-item {
  a {
    color: var(--primary-dark);
  }

  &:hover,
  &-active {
    border-color: var(--primary-dark);

    a {
      color: var(--primary-dark);
    }
  }
}

.ant-table-filter-trigger {
  color: var(--input-placeholder-color);

  &.active {
    color: var(--primary-dark-blue-2);
  }
}

.ant-dropdown-menu-item-selected {
  color: #1890ff;
  background-color: #e6f7ff;
}

.ant-table .ant-table-expanded-row-fixed {
  margin: 0;
}
