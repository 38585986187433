@import '~ng-zorro-antd/ng-zorro-antd.less';

@aiomed-primary-color: #263558;
// @aiomed-error-color: #E7424A;
@aiomed-error-color: #ff3141;

// global
@primary-color: @aiomed-primary-color;
@border-radius-base: 2px;
// @text-color: @aiomed-primary-color;

// inputs
@input-border-color: var(--neutral-5);
@input-placeholder-color: var(--placeholder-gray);
@input-color: var(--title-85);
// @input-height-base: 28px;
// @input-height-lg: 34px;
// @input-height-sm: 22px;
// @input-hover-border-color: #7F7B94;

// errors
@error-color: @aiomed-error-color;

// buttons
@btn-default-border: @aiomed-primary-color;
@btn-default-color: @aiomed-primary-color;
@btn-border-radius-base: 4px;

// notifications
@notification-padding-vertical: 9px !important;
@notification-padding-horizontal: 16px;

// avatar
@avatar-border-radius: 2px;

// header
@layout-header-padding: 0;
@layout-header-height: 67px;
