@use 'sass:meta';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@include meta.load-css('components.scss');

body {
  font-family: 'Lato', sans-serif;
}

:root {
  --primary-dark: #263558;
  --primary-dark-2: #252a36;
  --primary-dark-hover: #364b7c;
  --primary-dark-blue-2: #a6bae7;
  --primary-dark-blue-4: #617fc6;
  --primary-dark-blue-5: #5876bc;
  --secondary: #d4d4d4;
  --neutral-1: #ffffff;
  --neutral-2: #f6f6f9;
  --neutral-3: #efefef;
  --neutral-4: #ebecf1;
  --neutral-5: #dddede;
  --neutral-6: #afb7c7;
  --neutral-7: #a2afc3;
  --neutral-8: #758198;
  --neutral-9: #535d77;
  --primary-red-1: #f9eaea;
  --primary-red-2: #fce4e5;
  --primary-red-3: #fad9db;
  --primary-red-6: #e7424a;
  --primary-red-6-hover: #ff5962;
  --dust-red-1: #fff1f0;
  --dust-red-2: #ffccc7;
  --icon-grey: #b3b5b5;
  --critical: #e7424a;
  --major: #f28c15;
  --info: #6bb795;
  --success: #52c41a;
  --danger: #ff4d4f;
  --warning: #faad14;
  --volcano-1: #fff2e8;
  --volcano-2: #ffd8bf;
  --volcano-5: #ff7a45;
  --volcano-6: #fa541c;
  --success-green: #5be18e;
  --additional-green-2: #def9e8;
  --additional-green-7: #49b773;
  --additional-blue-2: #deeef7;
  --additional-blue-5: #5ba8d7;
  --card-border-color: #0000000f;
  --card-shadow: 0px 2px 8px 0px #00000026;
  --card-icon-shadow: 0px 0px 4px 0px #0000001a;
  --transition-time: 0.4s;
  --card-list-width: 282px;
  --input-placeholder-color: #00000040;
  --secondary-45: #00000073;
  --title-85: #000000d9;
  --aiomed-error-color: #ff3141;
  --polar-green-1: #f6ffed;
  --polar-green-3: #52c41a;
  --calendula-gold-1: #fffbe6;
  --calendula-gold-3: #ffe58f;
  --calendula-gold-6: #faad14;
  --primary-green-1: #f0f8f4;
  --primary-green-6: #89c5aa;
  --disabled-text-color: #00000040;
  --disabled-bg-color: #f5f5f5;
  --disabled-border-color: #d9d9d9;
  --scrollbar-color: #c1c1c1;
  --yellow-banner: #f7f9dd;
  --yellow-banner-2: #fefbbc;
  --violet-banner: #484cf8;
  --placeholder-gray: #758198;
  --header-dropdown--hover: #f1f2f5;
  --header-dropdown--border-color: #f4f4f8;
  --header-blue: #007bff;
  --select-bottom-border-color: #e8e8e9;
  --sidemenu-avatar-bg: #5daaff;
  --table-bg-even: rgb(from #efefef r g b / 30%);
  --disabled-grey: #b5bebe;
  --additional-green: #e6ffe6;
  --hover-bg-back-btn: #f1f2f4;
  --tablet-card-emr: #0fc180;
  --popup-backdrop-color: #252a36c7;
  --popup-box-shadow: 0px 28px 36px 0px rgba(38, 53, 88, 0.12);
  --scrolbar-thumb-color: #e2e3e6;
  --scrolbar-thumb-hover-color: #758198;
  --scrolbar-track-color: #efefef;
  --button-disabled: #BCC1C1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

span[nz-icon] {
  // color: var(--icon-grey);

  &[severity='critical'] {
    color: var(--critical);
  }

  &[severity='major'] {
    color: var(--major);
  }

  &[severity='info'] {
    color: var(--info);
  }
}

.ant-avatar-sm {
  min-width: 24px;
}

.ant-input {
  &:hover,
  &:focus {
    border-color: var(--primary-dark-blue-5);
  }
}

nz-embed-empty {
  margin: 0 auto;
}

.ant-input-number-group-addon {
  border-radius: var(--aiomed-border-radius-base);
}

.ant-picker {
  border-radius: var(--aiomed-border-radius-base);
}

.ant-avatar-string {
  position: static;
}
